import "core-js/modules/es.array.push.js";
import { myOperatorPage, setOperatorServer, getOperatorActiveServer, getOperatorActiveTeacher, setOperatorTeacher } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      goodsCodeValue: [],
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      dialogFormVisible1: false,
      pageInfo: {
        pageSize: 10,
        name: '',
        type: '',
        is_goods: 1,
        operator_active_name: '',
        operator_active_type: '',
        current: 1,
        state: '',
        total: 0
      },
      teacherData: {
        operator_active_id: '',
        teacherIds: []
      },
      serverData: {
        operator_active_id: '',
        serverIds: []
      },
      teacherList: [],
      serverList: []
    };
  },
  mounted() {
    this.getUserList();
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.type = '';
      this.pageInfo.state = '';
      this.pageInfo.operator_active_name = '';
      this.pageInfo.operator_active_type = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    closeDiv() {
      this.dialogFormVisible = false;
      this.dialogFormVisible1 = false;
    },
    seleType(e) {
      this.pageInfo.active_goods_type_code1 = e[0] || '';
      this.pageInfo.active_goods_type_code2 = e[1] || '';
    },
    getList() {
      myOperatorPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    getUserList() {
      getOperatorActiveServer({
        state: 2
      }).then(res => {
        this.serverList = res.data;
      });
      getOperatorActiveTeacher({
        state: 2
      }).then(res => {
        this.teacherList = res.data;
      });
    },
    showEdit(id, x) {
      if (x == 1) {} else {
        x = '';
      }
      this.$root.useRouter.push({
        path: "/operators/active/activeEdit",
        query: {
          id: id,
          is_goods: x
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    setServer(id) {
      this.dialogFormVisible = true;
      this.serverData.operator_active_id = id;
    },
    setTeacher(id, teacherIds) {
      this.dialogFormVisible1 = true;
      this.teacherData.operator_active_id = id;
      this.teacherData.teacherIds = teacherIds;
    },
    subTeacher() {
      setOperatorTeacher(this.teacherData).then(res => {
        this.$root.msgResult(res, '', this, 'getList');
        this.dialogFormVisible1 = false;
      });
    },
    subServer() {
      let that = this;
      if (!that.serverData.serverIds || that.serverData.serverIds.length == 0) {
        this.$root.ElMessage.error('请至少选择一个服务商发布');
        return false;
      }
      this.$root.ElMessageBox.confirm('注意：指定服务商发布后，活动的信息将不能修改(除了教师)，你确定要继续操作吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        draggable: true
      }).then(() => {
        setOperatorServer(that.serverData).then(res => {
          that.$root.msgResult(res, '', that, 'getList');
          that.dialogFormVisible = false;
        });
      });
    }
  }
};